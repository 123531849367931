import { Injectable } from '@angular/core';
import { Actions } from '@ngneat/effects-ng';
import { Observable } from 'rxjs';

import { ISProject, ISProjectDataRequest, ISProjectDetails, ISProjectUI } from '@ess/integrated-search/shared/utils';
import { SharedCrudActionEnum } from '@ess/shared/utils/enums';
import { SharedSortDirection } from '@ess/shared/utils/models';

import { IntegratedSearchProjectsDataAccessActions as ProjectsActions } from './integrated-search-projects-data-access.actions';
import { IntegratedSearchProjectsDataAccessRepository } from './integrated-search-projects-data-access.repository';

@Injectable()
export class IntegratedSearchProjectsDataAccessFacade {
  readonly projects$: Observable<ISProjectUI[]> = this.__repo.projects$;
  readonly error$: Observable<string | null> = this.__repo.error$;
  readonly activeProject$: Observable<ISProject | undefined> = this.__repo.activeProject$;
  readonly loading$: Observable<boolean> = this.__repo.loading$;
  readonly isActionLoading$: Observable<boolean> = this.__repo.isActionLoading$;
  readonly actionLoading$: Observable<SharedCrudActionEnum | null> = this.__repo.actionLoading$;
  readonly fetchedForClients$: Observable<string[]> = this.__repo.fetchedForClients$;
  readonly sort$: Observable<SharedSortDirection> = this.__repo.sort$;
  readonly allProjects$: Observable<ISProject[]> = this.__repo.allProjects$;
  readonly loadingForClient$: Observable<string | null> = this.__repo.loadingForClient$;
  readonly activeCurrencyCode$: Observable<string | null | undefined> = this.__repo.activeCurrencyCode$;
  readonly activeProjectDetails$: Observable<ISProjectDetails | null> = this.__repo.activeProjectDetails$;
  readonly activeProjectDetailsLoading$: Observable<boolean> = this.__repo.loadingDetails$;
  readonly activeProjectDetailsError$: Observable<string | null> = this.__repo.detailsError$;
  readonly activeProjectConfigurationInvalid$: Observable<boolean> = this.__repo.activeProjectConfigurationInvalid$;

  get activeProject(): number {
    return this.__repo.activeProject;
  }

  get activeProjectEntity(): ISProject | undefined {
    return this.__repo.activeProjectEntity;
  }

  get activeCurrencyCode(): string | null | undefined {
    return this.__repo.activeCurrencyCode;
  }

  constructor(
    private readonly __repo: IntegratedSearchProjectsDataAccessRepository,
    private readonly __actions: Actions,
  ) {}

  findProject(id: number): ISProject | null {
    return this.__repo.allProjects.find((p) => p.project_id === id) ?? null;
  }

  setActiveProject(id: number | null, clientSlug?: string): number | undefined {
    return this.__repo.setActiveProject(id, clientSlug);
  }

  loadProjectsForClient(slug: string): void {
    this.__actions.dispatch(ProjectsActions.loadProjects({ slug }));
  }

  toggleSort(): void {
    this.__actions.dispatch(ProjectsActions.toggleSort());
  }

  createProject(
    payload: ISProjectDataRequest,
    onSuccess?: (project: ISProjectDetails) => void,
    onError?: () => void,
  ): void {
    this.__actions.dispatch(ProjectsActions.createProject({ payload, onSuccess, onError }));
  }

  updateProject(projectId: number, payload: ISProjectDataRequest, onSuccess?: () => void, onError?: () => void): void {
    this.__actions.dispatch(ProjectsActions.updateProject({ projectId, payload, onSuccess, onError }));
  }

  deleteProject(projectId: number, onSuccess?: () => void, onError?: () => void): void {
    this.__actions.dispatch(ProjectsActions.deleteProject({ projectId, onSuccess, onError }));
  }
}
