import { Injectable } from '@angular/core';
import { createEffect, ofType, tapResult } from '@ngneat/effects';
import { filter, switchMap } from 'rxjs';

import { ISCategoriesForProject } from '@ess/integrated-search/shared/utils';

import { IntegratedSearchProjectsDataAccessCategoriesActions as CategoriesActions } from './integrated-search-projects-data-access-categories.actions';
import { IntegratedSearchProjectsDataAccessCategoriesRepository } from './integrated-search-projects-data-access-categories.repository';

import { IntegratedSearchProjectsDataAccessService } from '../services';
import { IntegratedSearchProjectsDataAccessActions as ProjectsActions } from '../state';

@Injectable()
export class IntegratedSearchProjectsDataAccessCategoriesEffects {
  loadCategories$ = createEffect((actions) =>
    actions.pipe(
      ofType(CategoriesActions.loadCategories, ProjectsActions.setActiveProjectSuccess),
      filter(({ projectId }) => {
        projectId ? this.__repo.setLoading() : this.__repo.resetStore();
        return !!projectId;
      }),
      switchMap(({ projectId }) =>
        this.__projectsService.getCategoriesForProject(projectId!).pipe(
          tapResult(
            (categoriesList: ISCategoriesForProject) =>
              this.__repo.saveCategories(
                projectId!,
                categoriesList.items.filter((category) => category.active),
              ),
            () => this.__repo.handleLoadCategoriesFail(),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly __projectsService: IntegratedSearchProjectsDataAccessService,
    private readonly __repo: IntegratedSearchProjectsDataAccessCategoriesRepository,
  ) {}
}
