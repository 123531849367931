import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ISCategoriesForProject,
  ISProjectDataRequest,
  ISProjectDetails,
  ISProjectsList,
} from '@ess/integrated-search/shared/utils';
import { SharedDataAccessApiService } from '@ess/shared/data-access';

@Injectable()
export class IntegratedSearchProjectsDataAccessService {
  private readonly __parentResource = 'clients';
  private readonly __resource = 'projects';

  constructor(private readonly __api: SharedDataAccessApiService) {}

  getProjectDetails(projectId: number): Observable<ISProjectDetails> {
    return this.__api.get<ISProjectDetails>(`${this.__resource}/${projectId}`);
  }

  getProjectsForClient(slug: string): Observable<ISProjectsList> {
    return this.__api.get(`${this.__parentResource}/${slug}/${this.__resource}`);
  }

  createProject(payload: ISProjectDataRequest): Observable<ISProjectDetails> {
    return this.__api.post<ISProjectDetails, ISProjectDataRequest>(this.__resource, payload);
  }

  updateProject(projectId: number, payload: ISProjectDataRequest): Observable<ISProjectDetails> {
    return this.__api.put<ISProjectDetails, ISProjectDataRequest>(`${this.__resource}/${projectId}`, payload);
  }

  deleteProject(projectId: number): Observable<never> {
    return this.__api.delete(`${this.__resource}/${projectId}`, null);
  }

  getCategoriesForProject(projectId: number): Observable<ISCategoriesForProject> {
    return this.__api.get<ISCategoriesForProject>(`${this.__resource}/${projectId}/categories`);
  }
}
