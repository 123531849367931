import { Injectable } from '@angular/core';
import { Actions } from '@ngneat/effects-ng';
import { map, Observable, withLatestFrom } from 'rxjs';

import { ISCategoryDetails } from '@ess/integrated-search/shared/utils';

import { IntegratedSearchProjectsDataAccessCategoriesActions as CategoriesActions } from './integrated-search-projects-data-access-categories.actions';
import { IntegratedSearchProjectsDataAccessCategoriesRepository } from './integrated-search-projects-data-access-categories.repository';

@Injectable()
export class IntegratedSearchProjectsDataAccessCategoriesFacade {
  readonly categories$: Observable<ISCategoryDetails[]> = this.__repo.categories$;
  readonly loading$: Observable<boolean> = this.__repo.loading$;
  readonly error$: Observable<string | null> = this.__repo.error$;
  readonly nullableCategories$: Observable<ISCategoryDetails[] | null> = this.__repo.categories$.pipe(
    withLatestFrom(this.__repo.error$),
    map(([categories, error]) => (error === null ? categories : null)),
  );

  get categories(): ISCategoryDetails[] {
    return this.__repo.categories;
  }

  constructor(
    private readonly __repo: IntegratedSearchProjectsDataAccessCategoriesRepository,
    private readonly __actions: Actions,
  ) {}

  refreshCategories(): void {
    const projectId: number | null = this.__repo.currentProjectId;
    projectId &&
      this.__actions.dispatch(
        CategoriesActions.loadCategories({
          projectId,
        }),
      );
  }

  updateCategories(categories: ISCategoryDetails[]): void {
    this.__repo.updateCategories(categories);
  }
}
