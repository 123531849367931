import { Injectable } from '@angular/core';
import { createEffect, ofType, tapResult } from '@ngneat/effects';
import { filter, map, switchMap, tap } from 'rxjs';

import { IntegratedSearchClientsDataAccessClientsActions as ClientsActions } from '@ess/integrated-search/clients/data-access';
import { ISProjectDetails, ISProjectsList } from '@ess/integrated-search/shared/utils';
import { SharedCrudActionEnum } from '@ess/shared/utils/enums';

import { IntegratedSearchProjectsDataAccessActions as ProjectsActions } from './integrated-search-projects-data-access.actions';
import { IntegratedSearchProjectsDataAccessRepository } from './integrated-search-projects-data-access.repository';

import { IntegratedSearchProjectsDataAccessService } from '../services';

@Injectable()
export class IntegratedSearchProjectsDataAccessEffects {
  loadProjects$ = createEffect((actions) =>
    actions.pipe(
      ofType(ProjectsActions.loadProjects, ClientsActions.setActiveClientSuccess),
      filter(({ slug }) => this.__repo.shouldBeFetched(slug)),
      tap(({ slug }) => this.__repo.setProjectsLoading(slug!)),
      switchMap(({ slug }) =>
        this.__projectsService.getProjectsForClient(slug!).pipe(
          tapResult(
            (projectsList: ISProjectsList) => this.__repo.saveProjects(projectsList.items),
            () => this.__repo.handleLoadProjectsFail(),
          ),
        ),
      ),
    ),
  );

  toggleSort$ = createEffect((actions) =>
    actions.pipe(
      ofType(ProjectsActions.toggleSort),
      map(() => this.__repo.toggleSort()),
    ),
  );

  createProject$ = createEffect((actions) =>
    actions.pipe(
      ofType(ProjectsActions.createProject),
      tap(() => this.__repo.setActionLoading(SharedCrudActionEnum.CREATE)),
      switchMap(({ payload, onSuccess, onError }) =>
        this.__projectsService.createProject(payload).pipe(
          tapResult(
            (project: ISProjectDetails) => this.__repo.handleProjectCreatedSuccess(project, onSuccess),
            () => this.__repo.handleActionError(onError),
            () => this.__repo.setActionLoading(null),
          ),
        ),
      ),
    ),
  );

  updateProject$ = createEffect((actions) =>
    actions.pipe(
      ofType(ProjectsActions.updateProject),
      tap(() => this.__repo.setActionLoading(SharedCrudActionEnum.UPDATE)),
      switchMap(({ projectId, payload, onSuccess, onError }) =>
        this.__projectsService.updateProject(projectId, payload).pipe(
          tapResult(
            (project: ISProjectDetails) => this.__repo.handleProjectUpdatedSuccess(project, onSuccess),
            () => this.__repo.handleActionError(onError),
            () => this.__repo.setActionLoading(null),
          ),
        ),
      ),
    ),
  );

  deleteProject$ = createEffect((actions) =>
    actions.pipe(
      ofType(ProjectsActions.deleteProject),
      tap(() => this.__repo.setActionLoading(SharedCrudActionEnum.DELETE)),
      switchMap(({ projectId, onSuccess, onError }) =>
        this.__projectsService.deleteProject(projectId).pipe(
          tapResult(
            () => this.__repo.handleProjectDeletedSuccess(projectId, onSuccess),
            () => this.__repo.handleActionError(onError),
            () => this.__repo.setActionLoading(null),
          ),
        ),
      ),
    ),
  );

  loadActiveProjectDetails$ = createEffect((actions) =>
    actions.pipe(
      ofType(ProjectsActions.loadActiveProjectDetails, ProjectsActions.setActiveProjectSuccess),
      filter(({ projectId }) => {
        !projectId && this.__repo.updateActiveProjectDetails(null);
        return !!projectId;
      }),
      tap(() => this.__repo.setDetailsLoading()),
      switchMap(({ projectId }) =>
        this.__projectsService.getProjectDetails(projectId!).pipe(
          tapResult(
            (details: ISProjectDetails) => this.__repo.updateActiveProjectDetails(details),
            () => this.__repo.updateActiveProjectDetails(null, true),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly __projectsService: IntegratedSearchProjectsDataAccessService,
    private readonly __repo: IntegratedSearchProjectsDataAccessRepository,
  ) {}
}
