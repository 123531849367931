import { NgModule } from '@angular/core';
import { provideEffects } from '@ngneat/effects-ng';

import {
  IntegratedSearchProjectsDataAccessCategoriesEffects,
  IntegratedSearchProjectsDataAccessCategoriesFacade,
  IntegratedSearchProjectsDataAccessCategoriesRepository,
} from './categories-state';
import { IntegratedSearchProjectsDataAccessService } from './services';
import {
  IntegratedSearchProjectsDataAccessEffects,
  IntegratedSearchProjectsDataAccessFacade,
  IntegratedSearchProjectsDataAccessRepository,
} from './state';

@NgModule({
  providers: [
    // Projects state
    IntegratedSearchProjectsDataAccessRepository,
    provideEffects(IntegratedSearchProjectsDataAccessEffects),
    IntegratedSearchProjectsDataAccessFacade,
    IntegratedSearchProjectsDataAccessService,
    // Categories state
    IntegratedSearchProjectsDataAccessCategoriesRepository,
    provideEffects(IntegratedSearchProjectsDataAccessCategoriesEffects),
    IntegratedSearchProjectsDataAccessCategoriesFacade,
  ],
})
export class IntegratedSearchProjectsDataAccessModule {}
