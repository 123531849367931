import { actionsFactory, props } from '@ngneat/effects';

import { ISProjectDataRequest, ISProjectDetails, ISStoreEnum } from '@ess/integrated-search/shared/utils';

/* eslint-disable @typescript-eslint/member-ordering */
export class IntegratedSearchProjectsDataAccessActions {
  private static readonly __factory = actionsFactory(ISStoreEnum.PROJECTS);

  static readonly loadProjects = this.__factory.create(
    'Fetch projects list for a client',
    props<{ slug: string | null }>(),
  );
  static readonly setActiveProjectSuccess = this.__factory.create(
    'Active project change success',
    props<{ projectId: number | null }>(),
  );
  static readonly toggleSort = this.__factory.create('Toggle sort');
  static readonly createProject = this.__factory.create(
    'Create new project',
    props<{
      payload: ISProjectDataRequest;
      onSuccess?: (project: ISProjectDetails) => void;
      onError?: () => void;
    }>(),
  );
  static readonly updateProject = this.__factory.create(
    'Update project',
    props<{
      projectId: number;
      payload: ISProjectDataRequest;
      onSuccess?: () => void;
      onError?: () => void;
    }>(),
  );
  static readonly deleteProject = this.__factory.create(
    'Delete project',
    props<{ projectId: number; onSuccess?: () => void; onError?: () => void }>(),
  );
  static readonly loadActiveProjectDetails = this.__factory.create(
    'Fetch details of active project',
    props<{ projectId: number }>(),
  );
}
