import { actionsFactory, props } from '@ngneat/effects';

import { ISStoreEnum } from '@ess/integrated-search/shared/utils';

/* eslint-disable @typescript-eslint/member-ordering */
export class IntegratedSearchProjectsDataAccessCategoriesActions {
  private static readonly __factory = actionsFactory(ISStoreEnum.PROJECTS_CATEGORIES);

  static readonly loadCategories = this.__factory.create(
    'Fetch categories list for a project',
    props<{ projectId: number | null }>(),
  );
}
