import { Injectable } from '@angular/core';
import { Actions } from '@ngneat/effects-ng';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  deleteEntitiesByPredicate,
  getActiveEntity,
  getAllEntities,
  resetActiveId,
  selectActiveEntity,
  selectAllEntities,
  setActiveId,
  updateEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { combineLatestWith, map, Observable, shareReplay } from 'rxjs';

import { IntegratedSearchClientsDataAccessClientsFacade } from '@ess/integrated-search/clients/data-access';
import {
  ISAccountStatusEnum,
  ISProject,
  ISProjectDetails,
  ISProjectSetup,
  ISProjectUI,
  ISStoreEnum,
} from '@ess/integrated-search/shared/utils';
import { SharedCrudActionEnum } from '@ess/shared/utils/enums';
import { SharedActiveEntitiesStore, SharedSortDirection } from '@ess/shared/utils/models';

import { IntegratedSearchProjectsDataAccessActions as ProjectsActions } from './integrated-search-projects-data-access.actions';

interface StoreProps {
  fetchedForClients: string[];
  loading: boolean;
  errors: Map<string, string>;
  actionLoading: SharedCrudActionEnum | null;
  currentClientSlug: string | null;
  sort: SharedSortDirection;
  activeProjectDetails: ISProjectDetails | null;
  loadingDetails: boolean;
  detailsError: string | null;
}

type Store = SharedActiveEntitiesStore<ISProject, number, StoreProps>;

@Injectable()
export class IntegratedSearchProjectsDataAccessRepository {
  get activeProject$(): Observable<ISProject | undefined> {
    return this.__store.pipe(selectActiveEntity());
  }

  get projects$(): Observable<ISProjectUI[]> {
    return this.__store.pipe(
      selectAllEntities(),
      shareReplay({ refCount: true }),
      combineLatestWith(this.__clientsFacade.activeClient$),
      map(([projects, client]) =>
        projects
          .filter((p) => p.client_slug === client?.slug)
          .map((p) => ({
            ...p,
            client: client,
          })),
      ),
      combineLatestWith(this.sort$),
      map(([projects, sort]) =>
        projects.sort((a, b) => a.project_name.localeCompare(b.project_name) * (sort === 'asc' ? 1 : -1)),
      ),
    );
  }

  get error$(): Observable<string | null> {
    return this.__store.pipe(
      select((state) => state.errors),
      combineLatestWith(this.__clientsFacade.activeClient$),
      map(([errors, client]) => errors.get(client?.slug ?? '') ?? null),
    );
  }

  get allProjects$(): Observable<ISProject[]> {
    return this.__store.pipe(
      selectAllEntities(),
      shareReplay({ refCount: true }),
      map((projects) => projects.sort((a, b) => a.project_name.localeCompare(b.project_name))),
    );
  }

  get allProjects(): ISProject[] {
    return this.__store.query(getAllEntities());
  }

  get loadingForClient$(): Observable<string | null> {
    return this.__store.pipe(select((state) => state.currentClientSlug));
  }

  get loading$(): Observable<boolean> {
    return this.__store.pipe(select((state) => state.loading));
  }

  get isActionLoading$(): Observable<boolean> {
    return this.__store.pipe(select((state) => state.actionLoading !== null));
  }

  get actionLoading$(): Observable<SharedCrudActionEnum | null> {
    return this.__store.pipe(select((state) => state.actionLoading));
  }

  get fetchedForClients$(): Observable<string[]> {
    return this.__store.pipe(select((state) => state.fetchedForClients));
  }

  get sort$(): Observable<SharedSortDirection> {
    return this.__store.pipe(select((state) => state.sort));
  }

  get activeProjectDetails$(): Observable<ISProjectDetails | null> {
    return this.__store.pipe(select((state) => state.activeProjectDetails));
  }

  get loadingDetails$(): Observable<boolean> {
    return this.__store.pipe(select((state) => state.loadingDetails));
  }

  get detailsError$(): Observable<string | null> {
    return this.__store.pipe(select((state) => state.detailsError));
  }

  get activeProjectConfigurationInvalid$(): Observable<boolean> {
    return this.__store.pipe(
      select((state) => (state.loadingDetails ? null : state.activeProjectDetails)),
      map(this.__isProjectConfigurationInvalid.bind(this)),
    );
  }

  get activeCurrencyCode$(): Observable<string | null | undefined> {
    return this.__store.pipe(
      select((state) => (state.loadingDetails ? null : state.activeProjectDetails)),
      map(this.__getCurrencyCode.bind(this)),
    );
  }

  get activeProject(): number {
    return this.__store.getValue().activeId;
  }

  get activeProjectEntity(): ISProject | undefined {
    return this.__store.query(getActiveEntity());
  }

  get activeCurrencyCode(): string | null | undefined {
    return this.__getCurrencyCode(this.activeProjectEntity);
  }

  private get __currentClientSlug(): string | null {
    return this.__store.getValue().currentClientSlug;
  }

  private get __fetchedForClients(): string[] {
    return this.__store.getValue().fetchedForClients;
  }

  private get __errors(): Map<string, string> {
    return new Map<string, string>(this.__store.getValue().errors);
  }

  private readonly __store: Store;

  constructor(
    private readonly __actions: Actions,
    private readonly __clientsFacade: IntegratedSearchClientsDataAccessClientsFacade,
  ) {
    this.__store = this.__createStore();
  }

  setActiveProject(id: number | null, clientSlug?: string): number | undefined {
    let activeProject: number | undefined;
    if (id && clientSlug && this.__store.getValue().entities[id]?.client_slug === clientSlug) {
      activeProject = id;
    }
    this.__store.update(activeProject ? setActiveId(activeProject) : resetActiveId());
    this.__actions.dispatch(ProjectsActions.setActiveProjectSuccess({ projectId: activeProject ?? null }));
    return activeProject;
  }

  shouldBeFetched(slug: string | null): boolean {
    const errors: string[] = Array.from(this.__errors.keys());

    return !!slug && !this.__fetchedForClients.filter((client) => !errors.includes(client)).includes(slug);
  }

  setProjectsLoading(slug: string): void {
    const errors: Map<string, string> = this.__errors;
    errors.delete(slug);
    this.__store.update(setProps({ loading: true, currentClientSlug: slug, errors }));
  }

  saveProjects(projects: ISProject[]): void {
    const projectsEntities: ISProject[] = projects.map(this.__parseProjectData.bind(this));

    this.__store.update(
      deleteEntitiesByPredicate((item) => item.client_slug === this.__currentClientSlug),
      addEntities(projectsEntities),
      setProps({
        loading: false,
        currentClientSlug: null,
        fetched: true,
        fetchedForClients: this.__getFetchedForClients(),
      }),
    );
  }

  handleLoadProjectsFail(): void {
    const errors: Map<string, string> = this.__errors;
    errors.set(
      this.__currentClientSlug!,
      'There was an error while fetching the projects for the current client. Please try again later.',
    );
    this.__store.update(
      deleteEntitiesByPredicate((item) => item.client_slug === this.__currentClientSlug),
      setProps({
        loading: false,
        currentClientSlug: null,
        fetchedForClients: this.__getFetchedForClients(),
        errors,
      }),
    );
  }

  toggleSort(): void {
    this.__store.update((state) => ({ ...state, sort: state.sort === 'asc' ? 'desc' : 'asc' }));
  }

  setActionLoading(action: SharedCrudActionEnum | null): void {
    this.__store.update(setProps({ actionLoading: action }));
  }

  handleProjectCreatedSuccess(project: ISProjectDetails, onSuccess?: (project: ISProjectDetails) => void): void {
    this.__store.update(addEntities(this.__parseProjectData(project)));
    if (onSuccess) {
      onSuccess(this.__uppercaseCodes<ISProjectDetails>(project));
    }
  }

  handleActionError(onError?: () => void): void {
    if (onError) {
      onError();
    }
    this.setActionLoading(null);
  }

  handleProjectUpdatedSuccess(project: ISProjectDetails, onSuccess?: () => void): void {
    this.__store.update(updateEntities(project.project_id, this.__parseProjectData(project)));

    this.updateActiveProjectDetails(project);
    if (onSuccess) {
      onSuccess();
    }
  }

  handleProjectDeletedSuccess(projectId: number, onSuccess?: () => void): void {
    this.__store.update(deleteEntities(projectId));
    if (onSuccess) {
      onSuccess();
    }
  }

  setDetailsLoading(): void {
    this.__store.update(
      setProps({
        loadingDetails: true,
        detailsError: null,
      }),
    );
  }

  updateActiveProjectDetails(details: ISProjectDetails | null, error = false): void {
    this.__store.update(
      setProps({
        activeProjectDetails: details ? this.__uppercaseCodes<ISProjectDetails>(details) : null,
        detailsError: error
          ? 'There was an error while fetching the details of current project. Please refresh the page or try again later.'
          : null,
        loadingDetails: false,
      }),
    );
  }

  private __createStore(): Store {
    return createStore(
      { name: ISStoreEnum.PROJECTS },
      withProps<StoreProps>({
        fetchedForClients: [],
        loading: true,
        errors: new Map<string, string>(),
        actionLoading: null,
        currentClientSlug: null,
        sort: 'asc',
        activeProjectDetails: null,
        loadingDetails: false,
        detailsError: null,
      }),
      withEntities<ISProject, 'project_id'>({ idKey: 'project_id' }),
      withActiveId(),
    );
  }

  private __getFetchedForClients(): string[] {
    const slug = this.__currentClientSlug;
    const fetched = new Set(this.__store.getValue().fetchedForClients);
    if (slug) {
      fetched.add(slug);
    }
    return Array.from(fetched);
  }

  private __parseProjectData({
    project_id,
    project_name,
    client_slug,
    default_country,
    currency_codes,
  }: ISProjectSetup | ISProject): ISProject {
    return {
      project_id,
      project_name,
      client_slug,
      ...this.__uppercaseCodes({ currency_codes, default_country }),
    };
  }

  private __uppercaseCodes<T extends Pick<ISProject, 'currency_codes' | 'default_country'>>(data: T): T {
    return {
      ...data,
      currency_codes: data.currency_codes.map((c) => c.toUpperCase()),
      default_country: data.default_country.toUpperCase(),
    };
  }

  private __isProjectConfigurationInvalid(project: ISProjectDetails | null): boolean {
    if (!project) {
      return false;
    }
    const currencyInvalid = !this.__getCurrencyCode(project);
    const gscInvalid: boolean =
      project.google_search_console_accounts.some(
        (account) => account.status && account.status !== ISAccountStatusEnum.VALID,
      ) || project.google_search_console_accounts.length === 0;
    const gadsInvalid: boolean =
      project.google_ads_accounts.some((account) => account.status && account.status !== ISAccountStatusEnum.VALID) ||
      project.google_ads_accounts.length === 0;
    return currencyInvalid || gscInvalid || gadsInvalid;
  }

  private __getCurrencyCode(project?: ISProject | null): string | null | undefined {
    return !project
      ? undefined
      : project.currency_codes.length === 0
      ? ''
      : project.currency_codes.length === 1 && project.currency_codes[0].length === 3
      ? project.currency_codes[0]
      : null;
  }
}
